import {useContext} from "react";
import {useTranslation} from "react-i18next";
import { useMediaQuery } from "react-responsive";
import Select from 'react-select';
import 'react-dropdown/style.css';
import {
    AvailOutputLanguagesContext,
    DropdownContext,
    InputLanguageContext,
    LanguageMappingContext,
    OutputLanguageContext
} from "../../context/Context";
import {ReactSVG} from "react-svg";
import Switch from "../../images/ic-switch.svg";
import {langCodesIntoArray} from "../../utils/functions";

const LanguageDropdown = ({ text, toggleDropdownMenu, dropdownText, language, changeLanguage, items, type }) => {
    const isDesktopOrTablet = useMediaQuery({query: '(min-width: 1024px)'});
    const {t, } = useTranslation();

    if (isDesktopOrTablet)  { //dropdown-container--language
        return (
            <div className={'dropdown-container dropdown--language__container'}>
                <p>{text} :</p>
                {/*<span className={'pipe'}/>*/}
                <Select
                    unstyled={true}
                    options={items}
                    filterOption={(item, input) => {
                            if (input) {
                                if (item.value === 'separator') {
                                    return false;
                                } else {
                                    return item.label.toString().toLowerCase().includes(input.toLowerCase());
                                }
                            }
                            return true;
                    }}
                    isOptionDisabled={(option) => option.value === 'separator'}
                    value={language}
                    onChange={(item) => {changeLanguage(item.value, type)}}
                    className={'dropdown dropdown--language'}
                    closeMenuOnScroll={false}
                    classNamePrefix={'dropdown--language'}
                    isSearchable={true}
                    //menuIsOpen={true} //for debugging
                    //arrowClosed={<span className="Dropdown-arrow arrow--closed" />}
                    //arrowOpen={<span className="Dropdown-arrow arrow--open" />}
                />
            </div>
        );
    }
    else {
        return (
            <div className={'dropdown-container dropdown-container__language'}>
                <div className='dropdown-container__box' onClick={() => toggleDropdownMenu(dropdownText)}>
                    <p className={'dropdown-container__text'}>
                        {text}: <br/><span style={{fontWeight: 'bold'}}>{language?.label}</span>
                    </p>
                </div>
                <div className={`dropdown dropdown--language dropdown--${dropdownText}`}>
                    <p>{t('Choose')} {text}</p>
                    <ul className={'dropdown__list'}>
                        {items.map((item, index) =>
                            {
                                if (item?.value === 'separator') {
                                    return (
                                        <li className={'dropdown__divider'} value={item?.value} key={index}>
                                            {item?.label}
                                        </li>
                                    )
                                } else {
                                    return (
                                        <li className={'dropdown__item'} value={item?.value} key={index}
                                            onClick={e => changeLanguage(e.target.getAttribute('value'), type)}>
                                            {item?.label}
                                        </li>
                                    )
                                }
                            }
                        )}
                    </ul>
                </div>
            </div>
        );
    }
}

const SwitchButton = ({switchLanguages, title}) => {
    return (
        <ReactSVG src={Switch} className={'switch-button'} onClick={switchLanguages} title={title} />
    )
}

export const Divider = () => {
    return { label: (<hr/>), value: 'separator' };
}

const DropDownContainer = ({switchLanguages}) => {
    const isDesktopOrTablet = useMediaQuery({query: '(min-width: 1024px)'})
    const {t,} = useTranslation()
    const languages = t('languages', {returnObjects: true})
    const priority = t('priorityLangs', {returnObjects: true})
    const {languageMapping} = useContext(LanguageMappingContext)
    const languageCodes  = Object.keys(languageMapping)

    const priorityLangs = []
    const otherLangs = []
    languageCodes.forEach(code => {
        if (priority.some(lang => lang.value === code)) {
            priorityLangs.push(priority.find(elem => elem.value === code))
        } else {
            otherLangs.push(languages.find(elem => elem.value === code))
        }
    })
    
    if (priorityLangs.length > 0 && otherLangs.length > 0) {
        priorityLangs.push(Divider())
        otherLangs.sort((a, b) => a?.label.localeCompare(b?.label))
    }
    const finalAvailInputLangs = priorityLangs.concat(otherLangs)

    const {inputLanguage, setInputLanguage} = useContext(InputLanguageContext);
    const {availOutputLanguages, setAvailOutputLanguages} = useContext(AvailOutputLanguagesContext)
    const {outputLanguage, setOutputLanguage} = useContext(OutputLanguageContext);
    const {toggleDropdown} = useContext(DropdownContext);

    const switchLanguageswithDropdown = () => {
        switchLanguages()
        const priorityOutputLangs = langCodesIntoArray(languageMapping[outputLanguage.value], priority)
        const otherOutputLangs = langCodesIntoArray(languageMapping[outputLanguage.value], languages)

        if (priorityOutputLangs.length > 0 && otherOutputLangs.length > 0) {
            priorityOutputLangs.push(Divider())
            otherOutputLangs.sort((a, b) => a?.label.localeCompare(b?.label))
        }
        const finalAvailOutputLangs = priorityOutputLangs.concat(otherOutputLangs)

        setAvailOutputLanguages(finalAvailOutputLangs)
    }

    const changeLanguage = (value, type) => {
        if (type === 'input') {
            const newLang = finalAvailInputLangs.find(el => el.value === value)
            setInputLanguage(newLang)
            const priorityOutputLangs = langCodesIntoArray(languageMapping[newLang.value], priority)
            const otherOutputLangs = langCodesIntoArray(languageMapping[newLang.value], languages)

            if (priorityOutputLangs.length > 0 && otherOutputLangs.length > 0) {
                priorityOutputLangs.push(Divider())
                otherOutputLangs.sort((a, b) => a?.label.localeCompare(b?.label))
            }
            const finalAvailOutputLangs = priorityOutputLangs.concat(otherOutputLangs)

            setAvailOutputLanguages(finalAvailOutputLangs)
            if (!finalAvailOutputLangs.includes(outputLanguage)) setOutputLanguage(finalAvailOutputLangs[0])

            // Close the dropdown menu and remove active classes from dropdown and header
            if (!isDesktopOrTablet) toggleDropdownMenu('inputlanguage');
        }
        else {
            const newLang = availOutputLanguages.find(el => el.value === value)
            setOutputLanguage(newLang)

            // Close the dropdown menu and remove active classes from dropdown and header
            if (!isDesktopOrTablet) toggleDropdownMenu('outputlanguage');
        }
    }

    const toggleDropdownMenu = choice => {
        const header = document.body.querySelector('header');
        header.classList.toggle('header--active');

        document.body.classList.toggle('fixedPositionDropdown');

        const dropdown = document.querySelector(`.dropdown--${choice}`);
        dropdown.classList.toggle('dropdown--active');

        toggleDropdown();
    };

    return (
        <div className={'translation-choices'}>
            <div className={'choice-container choice-container--input'}>
                <LanguageDropdown text={t('Input language')} toggleDropdownMenu={toggleDropdownMenu} dropdownText={'inputlanguage'}
                                   language={inputLanguage} changeLanguage={changeLanguage} items={finalAvailInputLangs} type={'input'} />
            </div>
            <SwitchButton switchLanguages={switchLanguageswithDropdown} title={t('Switch')} />
            <div className={'choice-container'}>
                <LanguageDropdown text={t('Output language')} toggleDropdownMenu={toggleDropdownMenu} dropdownText={'outputlanguage'}
                                    language={outputLanguage} changeLanguage={changeLanguage} items={availOutputLanguages} type={'output'} />
            </div>
        </div>
    );
}

export default DropDownContainer;
