import ReactGA from "react-ga4";
import { tartunlpApi } from "./variables";
import { useLayoutEffect, useState } from "react";
import axios from "axios";

export let requestData;
export let responseData;

export async function getTranslation(text, fromLanguage, toLanguage) {
    const fromLanguageValue = fromLanguage.value;
    const toLanguageValue = toLanguage.value;

    try {
        let response = await axios.post(`${tartunlpApi}/translation/v2`, {
            'text': text,
            'tgt': toLanguageValue,
            'src': fromLanguageValue,
        });
        // Check if test is running
        if (process.env.JEST_WORKER_ID === undefined) {
            // Save JSONs in case if user will edit translation
            // Test was failing because of this
            requestData = response.config.data;
            responseData = response.data.result;
        }
        return response.data;
    }
    catch (e) {
        return e;
    }

}

export async function getComparisons(text, fromLanguage, toLanguage) {
    let response = await axios.post(`${tartunlpApi}/translate_multi`, {
        text: text,
        target_lang: toLanguage.value,
        source_lang: fromLanguage.value,
    });
    return response.data;
}


export async function sendCorrection(correctedText, response, request) {
    await axios.post(`${tartunlpApi}/translation/v2/correction`, {
        correction: correctedText,
        response: response,
        request: request,
    });
}


export const scrollToRef = (ref) => {
    ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
    })
};

export const scrollToRefTop = (ref) => {
    const yOffset = -60;
    const y = ref.current.offsetTop + yOffset;

    window.scrollTo({ behavior: 'smooth', top: y })
};

export function googleLocation() {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.send("pageview");
}

export function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export function langCodesIntoArray(langCodes, languages) {
    let langObject = []
    for (const lang of languages) {
        if (langCodes.some(code => code === lang.value)) {
            langObject.push(lang)
        }
    }
    return langObject
}
