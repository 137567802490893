import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {ReactSVG} from "react-svg";
import Cap from "./images/cap.svg";
import Undefined from "./images/undefined.svg";
import TopHat from "./images/top-hat.svg";
import neurokone from "./images/icon-neurokone.svg"
import neurotolge from "./images/icon-neurotolge.svg"
import keelekorrektor from "./images/icon-keelekorrektor.svg"
import {initReactI18next} from "react-i18next";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
    // we init with resources
    resources: {
        en: {
            translations: {
                Features: 'Features',
                Collaboration: 'Collaboration',
                API: 'API',
                Info: 'Information',
                'All demos': 'All demos',
                'input box placeholder': 'Write your text here and we will translate it for you!',
                Compare: 'Compare translations',
                'What it does': 'What makes Neurotõlge special?',
                'All TartuNLP demos': 'All TartuNLP demos',
                'More_info_TartuNLP': 'More information about TartuNLP can be found <1>here</1>',
                Close: 'Close',
                'Output language': 'To',
                Style: 'Style',
                'Input language': 'From',
                'Edit translation hint': 'Click here to suggest\na better translation',
                priorityLangs: [
                    {label: 'Estonian', value: 'est'},
                    {label: 'English', value: 'eng'},
                    {label: 'German', value: 'ger'},
                    {label: 'Finnish', value: 'fin'},
                    {label: 'Russian', value: 'rus'},
                    //{label: 'Latvian', value: 'lav'},
                    //{label: 'Lithuanian', value: 'lit'},
                ],
                languages: [
                    //{label: 'Estonian', value: 'est'},
                    {label: 'Latvian', value: 'lav'},
                    {label: 'Lithuanian', value: 'lit'},
                    //{label: 'English', value: 'eng'},
                    //{label: 'Finnish', value: 'fin'},
                    //{label: 'German', value: 'ger'},
                    //{label: 'Russian', value: 'rus'},
                    {label: 'Norwegian', value: 'nor'},
                    {label: 'Hungarian', value: 'hun'},
                    {label: 'Võro', value: 'vro'},
                    {label: 'Northern Sami', value: 'sme'},
                    {label: 'Southern Sami', value: 'sma'},
                    {label: 'Inari Sami', value: 'smn'},
                    {label: 'Lule Sami', value: 'smj'},
                    {label: 'Skolt Sami', value: 'sms'},
                    {label: 'Meadow Mari', value: 'mhr'},
                    {label: 'Hill Mari', value: 'mrj'},
                    {label: 'Erzya', value: 'myv'},
                    {label: 'Proper Karelian', value: 'krl'},
                    {label: 'Khanty', value: 'kca'},
                    {label: 'Komi-Permyak', value: 'koi'},
                    {label: 'Komi', value: 'kpv'},
                    {label: 'Livvi Karelian', value: 'olo'},
                    {label: 'Ludian', value: 'lud'},
                    {label: 'Mansi', value: 'mns'},
                    {label: 'Moksha', value: 'mdf'},
                    {label: 'Udmurt', value: 'udm'},
                    {label: 'Veps', value: 'vep'},
                    {label: 'Livonian', value: 'liv'},
                    {label: 'Ukrainian', value: 'ukr'},
                    {label: 'Chinese', value: 'chi'},
                    {label: 'Spanish', value: 'spa'},
                    {label: 'French', value: 'fre'},
                    {label: 'Swedish', value: 'swe'}
                ],
                styles: [
                    {
                        label: 'informal',
                        displayName: <ReactSVG src={Cap}/>,
                        value: 'inf',
                        optionClass: 'dropdown__item dropdown__item--yellow'
                    },
                    {
                        label: 'auto',
                        displayName: <ReactSVG src={Undefined}/>,
                        value: 'auto',
                        optionClass: 'dropdown__item'
                    },
                    {
                        label: 'polite',
                        displayName: <ReactSVG src={TopHat}/>,
                        value: 'fml',
                        optionClass: 'dropdown__item dropdown__item--purple'
                    }

                ],
                Choose: 'Choose',
                demos: [
                    {
                        title: "Neurokõne",
                        description: "Estonian speech synthesis with a natural sound",
                        hoverClass: "white",
                        imgPath: neurokone,
                        url: "https://neurokone.ee"
                    },
                    {
                        title: "Neurotõlge",
                        description: "The most skilled translator of local languages",
                        hoverClass: "red",
                        imgPath: neurotolge,
                        url: "https://translate.ut.ee"
                    },
                    /*{
                        title: "Livesubs", description: "Simultaneous interpretation for conferences", hoverClass: "purple",
                        imgPath: "icon-livesubs.svg", url: "https://livesubs.ee"
                    },*/
                    {
                        title: "Named Entity Recognition",
                        description: "A clever tool for annotating named entities",
                        hoverClass: "yellow",
                        imgPath: keelekorrektor,
                        url: "https://ner.tartunlp.ai/"
                    }
                ],
                "Nothing found": "Sorry. There is nothing on this page.",
                "cookie consent": "This website uses cookies to improve user experience and we store user input and may " +
                    "share it with 3rd party services to provide additional functionality. By continuing to use this site, " +
                    "you agree to our <1>Data Protection Policy</1>.",
                "cookie button": "I agree",
                "Data Protection Policy": "Data Protection Policy",
                "Finno-Ugric translation": "Finno-Ugric translation",
                "New": "New",
                "Loading": "Loading...",
                "meta description": "Neurotõlge is a translation engine made by the NLP Research Group of University of Tartu." +
                    "It's possible to translate text and get translations from Google and Tilde to compare the translations.",
                "Clear": "Clear",
                "Copy": "Copy",
                "Edit": "Edit",
                "Switch": "Switch",
                "Apply": "Apply",
                "Cancel": "Cancel"
            }
        },
        et: {
            translations: {
                Collaboration: 'Koostöö',
                API: 'API',
                Info: 'Info',
                'All demos': 'Kõik demod',
                'input box placeholder': 'Kirjuta siia oma tekst ja me tõlgime selle ära!',
                Compare: 'Võrdle tõlkeid',
                'What it does': 'Mis teeb Neurotõlke eriliseks?',
                'All TartuNLP demos': 'Kõik TartuNLP demod',
                'More_info_TartuNLP': 'Rohkem infot TartuNLP kohta leiad <1>siit</1>',
                Close: 'Sulge',
                'Output language': 'Väljundkeel',
                Style: 'Stiil',
                'Input language': 'Sisendkeel',
                'Edit translation hint': 'Soovita paremat tõlget',
                priorityLangs: [
                    {label: 'eesti', value: 'est'},
                    {label: 'inglise', value: 'eng'},
                    {label: 'saksa', value: 'ger'},
                    {label: 'soome', value: 'fin'},
                    {label: 'vene', value: 'rus'},
                    //{label: 'läti', value: 'lav'},
                    //{label: 'leedu', value: 'lit'},
                ],
                languages: [
                    //{label: 'eesti', value: 'est'},
                    {label: 'läti', value: 'lav'},
                    {label: 'leedu', value: 'lit'},
                    //{label: 'inglise', value: 'eng'},
                    //{label: 'soome', value: 'fin'},
                    //{label: 'saksa', value: 'ger'},
                    //{label: 'vene', value: 'rus'},
                    {label: 'norra', value: 'nor'},
                    {label: 'ungari', value: 'hun'},
                    {label: 'võro', value: 'vro'},
                    {label: 'põhjasaami', value: 'sme'},
                    {label: 'lõunasaami', value: 'sma'},
                    {label: 'inarisaami', value: 'smn'},
                    {label: 'lulesaami', value: 'smj'},
                    {label: 'skoltsaami', value: 'sms'},
                    {label: 'niidumari', value: 'mhr'},
                    {label: 'mäemari', value: 'mrj'},
                    {label: 'ersa', value: 'myv'},
                    {label: 'päriskarjala', value: 'krl'},
                    {label: 'handi', value: 'kca'},
                    {label: 'permikomi', value: 'koi'},
                    {label: 'sürjakomi', value: 'kpv'},
                    {label: 'livviko', value: 'olo'},
                    {label: 'lüüdi', value: 'lud'},
                    {label: 'mansi', value: 'mns'},
                    {label: 'mokša', value: 'mdf'},
                    {label: 'udmurdi', value: 'udm'},
                    {label: 'vepsa', value: 'vep'},
                    {label: 'liivi', value: 'liv'},
                    {label: 'ukraina', value: 'ukr'},
                    {label: 'hiina', value: 'chi'},
                    {label: 'hispaania', value: 'spa'},
                    {label: 'prantsuse', value: 'fre'},
                    {label: 'rootsi', value: 'swe'}
                ],
                styles: [
                    {
                        label: 'kõnekeel',
                        displayName: <ReactSVG src={Cap}/>,
                        value: 'inf',
                        optionClass: 'dropdown__item dropdown__item--yellow'
                    },
                    {
                        label: 'valimata',
                        displayName: <ReactSVG src={Undefined}/>,
                        value: 'auto',
                        optionClass: 'dropdown__item'
                    },
                    {
                        label: 'ametlik',
                        displayName: <ReactSVG src={TopHat}/>,
                        value: 'fml',
                        optionClass: 'dropdown__item dropdown__item--purple'
                    }
                ],
                Choose: 'Vali',
                demos: [
                    {
                        title: "Neurokõne",
                        description: "Loomuliku kõlaga eestikeelne kõnesüntesaator",
                        hoverClass: "white",
                        imgPath: neurokone,
                        url: "https://neurokone.ee"
                    },
                    {
                        title: "Neurotõlge",
                        description: "Kõige vilunum kohalikes keeltes tõlkija",
                        hoverClass: "red",
                        imgPath: neurotolge,
                        url: "https://translate.ut.ee"
                    },
                    /*{
                        title: "Livesubs", description: "Sünkroontõlge konverentsidel", hoverClass: "purple",
                        imgPath: "icon-livesubs.svg", url: "https://livesubs.ee"
                    },*/
                    {
                        title: "Nimeolemite tuvastaja",
                        description: "Andekas nimeüksuste märgendaja",
                        hoverClass: "yellow",
                        imgPath: keelekorrektor,
                        url: "https://ner.tartunlp.ai/"
                    }
                ],
                "Nothing found": "Vabandust. Selline leht puudub.",
                "cookie consent": "See veebileht kasutab parema kasutuskogemuse tagamiseks küpsiseid ning kasutajate " +
                    "sisendeid salvestakse ja võidakse edastada kolmandate osapoolte teenustele, kui see on vajalik " +
                    "lehe funktsionaalsuse tagamiseks. Kasutamist jätkates nõustute meie <1>andmekaitsetingimustega</1>.",
                "cookie button": "Nõustun",
                "Data Protection Policy": "Andmekaitsetingimused",
                "Finno-Ugric translation": "Soome-ugri tõlkemootor",
                "New": "Uus",
                "Loading": "Laadin...",
                "meta description": "Neurotõlge on Tartu Ülikooli NLP uurimisrühma loodud tõlkemootor. " +
                    "Teksti on võimalik tõlkida ja näha Google'i ning Tilde tõlget võrdluseks.",
                "Clear": "Puhasta",
                "Copy": "Kopeeri",
                "Edit": "Muuda",
                "Switch": "Vaheta",
                "Apply": "Kinnita",
                "Cancel": "Tühista"
            }
        }
    },
    fallbackLng: "et",
    debug: false,
    load: 'languageOnly',

    // have a common namespace used around the full home
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
    },

    react: {
        useSuspense: true,
    }
});

export default i18n;
