import {useEffect, useRef, useState} from 'react';
import './App.scss';
import Header from "./header/Header";
import Home from "./home/Home";
import NotFoundPage from "./not-found-page/NotFoundPage";
import OtherPage from "./other-page/OtherPage";
import {api, collaboration, dataProtectionPolicy} from "../utils/variables";
import {Trans, useTranslation} from "react-i18next";
import { Route, Routes, useLocation } from 'react-router-dom';
import CookieConsent, {Cookies}  from "react-cookie-consent";
import ReactGA from 'react-ga4';
import {googleLocation} from "../utils/functions";
import {DropdownContext, GlobalProvider} from "../context/Context";
import {Helmet} from "react-helmet";

const Main = ({isCookiePresent}) => {
    const {t, } = useTranslation();

    return (
        <Routes>
            <Route exact path={'/'} element={<Home />} />
            <Route path={'/collaboration'} element={<OtherPage title={t('Collaboration')} pageId={collaboration} />} />
            <Route path={'/api'} element={<OtherPage title={t('API')} pageId={api} />} />
            <Route path={'/data-protection-policy'} element={<OtherPage title={t('Data Protection Policy')} pageId={dataProtectionPolicy} isCookiePresent={isCookiePresent} />} />
            <Route path={'*'} element={<NotFoundPage />} />
        </Routes>
    )
};

const App = () => {
    const {t, i18n} = useTranslation();
    const [dropdownOpen, setDropdown] = useState(false);

    const cookieRef = useRef()
    const [isCookiePresent, setCookiePresent] = useState(false)

    const toggleDropdown = () => {
        setDropdown(!dropdownOpen);
    }

    useEffect(() => {
        const cookie = Cookies.get('Neurotolge');
        if (cookie) {
            ReactGA.initialize(window.REACT_APP_ANALYTICS_KEY);
            googleLocation();
        }

        if (cookieRef.current && !cookie) {
            setCookiePresent(true)
        }
        else {
            setCookiePresent(false)
        }
    }, []);

    // If URL changes, send data to Google Analytics
    const location = useLocation();
    useEffect(() => {
        const cookie = Cookies.get("Neurotolge");
        if (cookie) googleLocation();
    }, [location]);

    return (
        <GlobalProvider>
            <DropdownContext.Provider value={{ dropdownOpen, toggleDropdown }}>
                <Helmet defaultTitle={t('Neurotõlge')} titleTemplate={`%s | ${t('Neurotõlge')}`}>
                    <html lang={i18n.language.split("-")[0]} />
                    <title lang={i18n.language.split("-")[0]} />
                    <meta name="description" content={t('meta description')} />
                    <meta property="og:title" content={t('Neurotõlge')} />
                    <meta name="twitter:title" content={t('Neurotõlge')} />
                    <meta property="og:description" content={t('meta description')} />
                    <meta name="twitter:description" content={t('meta description')} />
                </Helmet>

                <Header />
                <Main isCookiePresent={isCookiePresent}/>
                <CookieConsent
                    ref={cookieRef}
                    location="bottom"
                    cookieName="Neurotolge"
                    sameSite="strict"
                    buttonText={t("cookie button")}
                    containerClasses={"cookie-container"}
                    contentClasses={"text--cookie"}
                    buttonClasses={"button--cookie"}
                    disableButtonStyles={true}
                    contentStyle={{
                        marginLeft: "0",
                        marginRight: "0",
                        paddingRight: "10px",
                        flexBasis: "auto",
                        boxSizing: "border-box"
                    }}
                    onAccept={() => {
                        ReactGA.initialize(window.REACT_APP_ANALYTICS_KEY);
                        googleLocation();
                        setCookiePresent(false)
                    }}
                >
                    <Trans i18nKey={'cookie consent'}>
                        This website uses cookies to improve user experience and we store user input and may share
                        it with 3rd party services to provide additional functionality. By continuing to use this
                        site, you agree to our <a href={"/data-protection-policy"} rel="noopener noreferrer" target={"_blank"}>Data Protection Policy</a>.
                    </Trans>
                </CookieConsent>
            </DropdownContext.Provider>
        </GlobalProvider>
    );
}

export default App;
