import {useContext, useState} from 'react';
import {Link} from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import {ReactSVG} from "react-svg";
import NeurotolgeLogo from "../../../images/neurotolge-logo.svg";
import {useTranslation} from "react-i18next";
import "./Navigation.scss";
import {
    AvailOutputLanguagesContext,
    DropdownContext,
    InputLanguageContext,
    LanguageMappingContext,
    OutputLanguageContext
} from "../../../context/Context";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faTimes} from "@fortawesome/free-solid-svg-icons";
import {langCodesIntoArray} from "../../../utils/functions";
import {Divider} from "../../translation/Dropdown";

const Navigation = ({clickDemoButton}) => {
    const {t, i18n} = useTranslation();
    const [navOpen, toggleNav] = useState(false);

    const {outputLanguage, setOutputLanguage} = useContext(OutputLanguageContext);
    const {inputLanguage, setInputLanguage} = useContext(InputLanguageContext);
    const {dropdownOpen, toggleDropdown} = useContext(DropdownContext);
    const {languageMapping} = useContext(LanguageMappingContext)
    const {setAvailOutputLanguages} = useContext(AvailOutputLanguagesContext)

    const handleLanguageChange = (e) => {
        e.preventDefault();
        const language = e.target.id;
        i18n.changeLanguage(language)
            .then(() => {
                const priority = t('priorityLangs', {returnObjects: true})
                const languages = t('languages', {returnObjects: true})

                // Change the output language to get the new label text
                const newOutputLang = priority.concat(languages).filter(el => {
                    return el.value === outputLanguage.value
                })[0];
                setOutputLanguage(newOutputLang);

                // Change the input language to get the new label text
                const newInputLang = priority.concat(languages).filter(el => {
                    return el.value === inputLanguage.value
                })[0];
                setInputLanguage(newInputLang);

                // Change the available output languages to show correct label texts in the dropdown
                const newAvailPriorityOutputLangs = langCodesIntoArray(languageMapping[newInputLang.value], priority)
                const newAvailOtherOutputLangs = langCodesIntoArray(languageMapping[newInputLang.value], languages)
                if (newAvailOtherOutputLangs.length > 0) {
                    newAvailPriorityOutputLangs.push(Divider())
                }
                setAvailOutputLanguages(newAvailPriorityOutputLangs.concat(newAvailOtherOutputLangs))
            });
    }

    const toggleNavOpen = () => {
        const header = document.body.querySelector('header');
        header.classList.toggle('header--nav-open');

        // So the background doesn't scroll when scrolling the open hamburger menu
        document.body.classList.toggle('body--fixed');

        const demoOverlay = document.body.querySelector('.demo-overlay');
        demoOverlay.classList.toggle('demo-overlay--active');

        const menuItems = document.body.querySelectorAll('.nav__item');
        if (navOpen) {
            menuItems.forEach((item) => {
                item.classList.remove('nav__item--active');
            });
        }
        else {
            menuItems.forEach((item) => {
                item.classList.add('nav__item--active');
            });
        }

        toggleNav(!navOpen);
    }

    const closeNav = () => {
        const header = document.body.querySelector('header');
        header.classList.remove('header--nav-open');

        document.body.classList.remove('body--fixed');

        const demoOverlay = document.body.querySelector('.demo-overlay');
        demoOverlay.classList.remove('demo-overlay--active');

        const menuItems = document.body.querySelectorAll('.nav__item');
        menuItems.forEach((item) => item.classList.remove('nav__item--active'));

        toggleNav(false);
    }

    const closeDropdown = () => {
        const header = document.body.querySelector('header');
        header.classList.remove('header--active');

        // TODO: muuda body klassi nimi ära ja kontrolli, kas seda üldse vaja on
        document.body.classList.remove('fixedPositionDropdown');

        const dropdowns = document.querySelectorAll('.dropdown');
        dropdowns.forEach((dropdown) => {
            dropdown.classList.remove('dropdown--active')
        });

        toggleDropdown();
    }

    let toggle;
    // If you open output language or style dropdown, change the toggle button
    if (dropdownOpen && !navOpen) {
        toggle = <li className={'nav__item toggle toggle--open toggle--dropdown'} onClick={() => closeDropdown()}>
            <FontAwesomeIcon icon={faTimes}/></li>;
    }
    else if (navOpen && !dropdownOpen) {
        toggle = <li className={'nav__item toggle toggle--open'} onClick={() => toggleNavOpen()}><FontAwesomeIcon
            icon={faTimes}/></li>;
    }
    else {
        toggle =
            <li className={'nav__item toggle'} onClick={() => toggleNavOpen()}><FontAwesomeIcon icon={faBars}/></li>;
    }

    return (
        <nav className='nav'>
            <ul className='nav__list'>
                <li className={'nav__item logo'} onClick={() => closeNav()}>
                    <Link to={'/'} className='nav__link'><ReactSVG src={NeurotolgeLogo}/></Link>
                </li>
                <li className='nav__item' onClick={() => closeNav()}>
                    <HashLink to={"/#info"} className='nav__link' data-testid={'nav__item--lang'}
                              scroll={el => el.scrollIntoView({behavior: 'smooth', block: "start"})}>
                        {t('Info')}
                    </HashLink>
                </li>
                <li className='nav__item' onClick={() => closeNav()}>
                    <Link to={'/collaboration'} className='nav__link' data-testid={'nav__item'}>
                        {t('Collaboration')}
                    </Link>
                </li>
                <li className='nav__item' onClick={() => closeNav()}>
                    <Link to={'/api'} className='nav__link'>
                        {t('API')}
                    </Link>
                </li>
                <li className='nav__item nav__button' onClick={clickDemoButton} data-testid={'button--demo'}>
                    {t('All demos')}
                </li>

                {toggle}

                {i18n.language.split("-")[0] === 'en'
                    ? (
                        <>
                            <li className={`nav__item nav__lang-change nav__lang-change--active ${navOpen ? 'nav__item--active' : ''}`}
                                id={'en'} onClick={e => handleLanguageChange(e)}>EN
                            </li>
                            <li className={`nav__item nav__lang-change ${navOpen ? 'nav__item--active' : ''}`} id={'et'}
                                onClick={e => handleLanguageChange(e)} data-testid={'nav__lang-change'}>ET
                            </li>
                        </>
                    )
                    : (
                        <>
                            <li className={`nav__item nav__lang-change ${navOpen ? 'nav__item--active' : ''}`} id={'en'}
                                onClick={e => handleLanguageChange(e)}>EN
                            </li>
                            <li className={`nav__item nav__lang-change nav__lang-change--active ${navOpen ? 'nav__item--active' : ''}`}
                                id={'et'} onClick={e => handleLanguageChange(e)}>ET
                            </li>
                        </>
                    )
                }
            </ul>
        </nav>
    );
}

export default Navigation;
