import {ReactSVG} from "react-svg";
import scrollDownArrow from '../../images/scroll-down-arrow.svg';
import {useTranslation} from "react-i18next";

const ScrollDown = () => {
    const {t, } = useTranslation();

    return (
        <section className={'scroll-container'}>
            <ReactSVG src={scrollDownArrow} className={'section__image section__image--scroll'} />
            <p className={'section__subtitle section__subtitle--scroll'}>{t('What it does')}</p>
        </section>
    );
};

export default ScrollDown;
