import {useCallback, useEffect, useRef, useState} from 'react';
import Navigation from "./navigation/Navigation";
import DemoOverlay from "./demo-overlay/DemoOverlay";

const Header = () => {
    const [demoSlideInOpen, setDemoSlideIn] = useState(false);
    const overlayRef = useRef()

    const handleDemosSlideIn = useCallback(() => {
        setDemoSlideIn(!demoSlideInOpen);
    }, [demoSlideInOpen])

    useEffect(() => {
        function handleClickOutside(event) {
            if (overlayRef.current && !overlayRef.current.contains(event.target) && demoSlideInOpen) {
                handleDemosSlideIn()
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [overlayRef, demoSlideInOpen, handleDemosSlideIn])

    return (
        <header className={'header'}>
            <Navigation clickDemoButton={handleDemosSlideIn} />
            <DemoOverlay demosSlideInOpen={demoSlideInOpen} handleDemosSlideIn={handleDemosSlideIn} overlayRef={overlayRef}/>
        </header>
    );

}

export default Header;
