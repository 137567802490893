import {useState, useEffect} from "react";
import "./OtherPage.scss";
import {wordpressUrl, cleanHtml} from "../../utils/variables";
import {useTranslation} from "react-i18next";
import axios from "axios";
import Loading from "../Loading";
import {Helmet} from "react-helmet";

const OtherPage = ({title, pageId, isCookiePresent}) => {
    const [data, setData] = useState(null);
    const {i18n} = useTranslation();
    let helmet = <Helmet><title>{title}</title></Helmet>;

    useEffect(() => {
        const lang = i18n.language.split("-")[0];

        axios.get(`${wordpressUrl}/pages/${pageId}?lang=${lang}`)
            .then(response => response.data)
            .then(result => {
                let cleanedContent = cleanHtml(result.content.rendered)
                setData(cleanedContent);
            })
            .catch(error =>console.error(error));

    }, [i18n.language, pageId]);

    if (!data) {
        return (
            <section className={'page'}>
                {helmet}
                <h1 className={'section__title section__title--page'} data-testid={'other-page-title'}>{title}</h1>
                <Loading className={'loading--margin'} />
            </section>
        )
    }
    else {
        return (
            <section className={`page ${isCookiePresent ? 'page__bigmargin' : ''}`}>
                {helmet}
                <h1 className={'section__title section__title--page'} data-testid={'other-page-title'}>{title}</h1>
                <div className={'section__text'} data-testid={'other-page-text'} dangerouslySetInnerHTML={{__html: data}}/>
            </section>
        );
    }
};

export default OtherPage;
