import {useMediaQuery} from "react-responsive";
import {useWindowSize} from "../../utils/functions";
import TranslationContainer from "../translation/TranslationContainer";
import ScrollDown from "../description-block/ScrollDown"
import DescriptionBlock from "../description-block/DescriptionBlock"

const Viewport = () => {
    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});
    const [, height] = useWindowSize();
    const viewportHeight = isDesktopOrLaptop ? height - 90 : height - 74 - 16;
    const translationContainerHeight = viewportHeight - 42 - 37 - 20;

    if (isDesktopOrLaptop) {
        return (
            <section className={'section__viewport'} style={{minHeight: `${viewportHeight}px`}} data-testid={'viewport'}>
                <TranslationContainer height={translationContainerHeight}  />
                <ScrollDown />
            </section>
        )
    }
    else {
        return (
            <section className={'section__viewport'} style={{minHeight: `${viewportHeight}px`, height: `${viewportHeight - 16}px`}} data-testid={'viewport'}>
                <TranslationContainer />
            </section>
        )
    }
}

const Home = () => {
    return (
        <>
            <Viewport />
            <DescriptionBlock />
        </>
    );
}

export default Home;
