import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faTimes, faEdit, faCheck } from "@fortawesome/free-solid-svg-icons";

const CopyButton = ({ text, className, title }) => {
    const copyToClipboard = () => {
        if (typeof (navigator.clipboard) !== 'undefined') { // Chrome
            navigator.clipboard.writeText(text)
                .catch(e => console.error(e))
        }
        else if (window.clipboardData) { // Internet Explorer
            window.clipboardData.setData("Text", text)
        }
    }

    return (
        <FontAwesomeIcon icon={faCopy} className={className} onClick={copyToClipboard} title={title} />
    )
}

const ClearButton = ({ clear, title }) => {
    return (
        <FontAwesomeIcon icon={faTimes} className={'box__clear-button'} onClick={clear} title={title} />
    )
}

const EditButton = ({ edit, title, containerRef }) => {
    return (
        <div className="box__container-editing-buttons" ref={containerRef}>
            <FontAwesomeIcon icon={faEdit} className={'box__edit-button'} onClick={edit} title={title} />
        </div>
    )
}

const EditingButtons = ({ accept, cancel }) => {
    const { t, } = useTranslation();
    return (
        <div className={'box__container-editing-buttons'}>
            <FontAwesomeIcon icon={faCheck} id={"accept"} className={'box__accept-button'} onClick={accept} title={t('Apply')} />
            <FontAwesomeIcon icon={faTimes} id={"cancel"} className={'box__cancel-button'} onClick={cancel} title={t('Cancel')} />
        </div>
    )
}

const Box = ({ inputBox, maxChars, text, handleTextChange, charsUsed, compareButtonVisible, outputBoxRef, clearText = null,
    outputAreaRef, readOnly, editOutput, acceptEditing, cancelEditing, editButtonRef }) => {
    const { t, } = useTranslation();
    
    if (inputBox) {
        const placeholderText = t('input box placeholder');
        return (
            <div className={'box box--input'}>
                <textarea className={'div__textarea'} id={'input-textarea'} placeholder={placeholderText} onChange={handleTextChange}
                    maxLength={maxChars} value={text} autoFocus data-testid={'input-textarea'} />
                {text.length !== 0
                    ? <ClearButton clear={clearText} title={t('Clear')} />
                    : null
                }
                <div className={'box__character-count'}>
                    <span>{charsUsed} / {maxChars}</span>
                    <CopyButton className={'box__input-copy'} text={text} title={t('Copy')} />
                </div>
            </div>
        );
    } else {
        return (
            <div className={'box' + (compareButtonVisible ? ' box--show-compare' : '')} id={'output-box'} ref={outputBoxRef}>
                <textarea className={'div__textarea'} id={'output-textarea'} readOnly={readOnly} value={text} onChange={handleTextChange}
                          ref={outputAreaRef} data-testid={'output-textarea'} />
                {text.length !== 0
                    ? readOnly
                        ? <EditButton id="edit" edit={editOutput} title={t('Edit')} containerRef={editButtonRef} />
                        : <EditingButtons accept={acceptEditing} cancel={cancelEditing} />
                    : !readOnly
                        ? <EditingButtons id="edit" accept={acceptEditing} cancel={cancelEditing} />
                        : null
                }
                <CopyButton className={'box__output-copy'} text={text} title={t('Copy')} />
            </div>
        );
    }
};

export default Box;
