import {useRef, useEffect, useState} from "react";
import "./DescriptionBlock.scss";
import {wordpressUrl, cleanHtml, mainPageInfo} from "../../utils/variables";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import {scrollToRef} from "../../utils/functions";
import axios from "axios";

const DescriptionBlock = () => {
    const [content, setContent] = useState('');
    // eslint-disable-next-line no-unused-vars
    const {t, i18n} = useTranslation();
    const infoRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        const lang = i18n.language.split("-")[0];
        let url = `${wordpressUrl}/pages/${mainPageInfo}?lang=${lang}`;
        let isMounted = true

        axios.get(url)
            .then(response => response.data)
            .then(result => {
                let cleanedContent = cleanHtml(result.content.rendered);
                if (isMounted) setContent(cleanedContent);
            })
            .catch(error => console.error(error));
        return () => { isMounted = false }
    }, [i18n.language, setContent]);

    useEffect(() => {
        if (location.hash === '#info') {
            scrollToRef(infoRef);
        }
    }, [infoRef, location, content]);

    return (
        <div className={'info'} id={'info'} ref={infoRef}>
            <div dangerouslySetInnerHTML={{__html: content}} />
        </div>
    )
};

export default DescriptionBlock;
