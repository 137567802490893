import {createContext, useEffect, useState} from 'react';
import {tartunlpApi, originalInputLanguage, originalOutputLanguage} from "../utils/variables";
import {langCodesIntoArray} from "../utils/functions";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {Divider} from "../components/translation/Dropdown";

// Output language context
export const OutputLanguageContext = createContext({
    value: {label: 'inglise keel', value: 'en'},
    changeLanguage: () => {}
});

// Input language context
export const InputLanguageContext = createContext({
    value: {label: 'eesti keel', value: 'et'},
    changeLanguage: () => {}
});

export const DropdownContext = createContext(false);

// Available output languages context
export const AvailOutputLanguagesContext = createContext({
    value: [],
    changeAvailOutputLanguages: () => {}
});

// Input language and available output languages context
export const LanguageMappingContext = createContext({
    value: {},
    changeLanguageMapping: () => {}
});

export const GlobalProvider = props => {
    const {t} = useTranslation()
    const priority = t('priorityLangs', {returnObjects: true})
    const languages = t('languages', {returnObjects: true})
    const inLang = priority.concat(languages).find(({value='est', label = 'Estonian'}) => value === originalInputLanguage)
    const [inputLanguage, setInputLanguage] = useState(inLang);
    const outLang = priority.concat(languages).find(({value='eng', label = 'English'}) => value === originalOutputLanguage)
    const [outputLanguage, setOutputLanguage] = useState(outLang);
    const [availOutputLanguages, setAvailOutputLanguages] = useState([])
    const [languageMapping, setLanguageMapping] = useState({})


    const fetchLanguages = async () => {
        // Get language pairs from API
        await axios.get(`${tartunlpApi}/translation/v2`)
            .then(response => response.data)
            .then(result => {
                // Split the languages from "-" to get different languages
                const languagesArray = result?.domains[0]?.languages
                //console.log(`languagesArray: ${languagesArray}`)
                const splitArray = languagesArray.map(elem => elem.split("-"))

                // Make a set of all the languages
                const languageSet = new Set(splitArray.map(elem => elem[0]))
                // Generate an object with key:value where key is the language code and value is a set of available languages
                let languageObject = {}
                languageSet.forEach(language => {
                    // Only keep the subarrays that contain the language
                    const filteredArray = splitArray.filter(lst => lst[0] === language)
                    // Flatten the filtered array of arrays and make it into a set, then add to languageObject and sort it
                    languageObject[language] = [...new Set([].concat.apply([], filteredArray.map(elem => elem[1])))]
                })

                const availPriorityOutputLangs = langCodesIntoArray(languageObject[inputLanguage.value], priority)
                const otherLangs = langCodesIntoArray(languageObject[inputLanguage.value], languages)

                if (availPriorityOutputLangs.length > 0 && otherLangs.length > 0) {
                    availPriorityOutputLangs.push(Divider())
                    otherLangs.sort((a, b) => a?.label.localeCompare(b?.label))
                }
                const finalAvailOutputLangs = availPriorityOutputLangs.concat(otherLangs)

                setLanguageMapping(languageObject)
                setOutputLanguage(finalAvailOutputLangs[0] !== inputLanguage ? finalAvailOutputLangs[0] : finalAvailOutputLangs[1])
                setAvailOutputLanguages(finalAvailOutputLangs)
            })
            .catch(e => {
                // TODO: error message when unable to fetch languages
                console.error("Unable to fetch languages")
            })
    }

    useEffect(() => {
        fetchLanguages()
            .catch(e => console.error(e))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <LanguageMappingContext.Provider value={{ languageMapping, setLanguageMapping }}>
            <AvailOutputLanguagesContext.Provider value={{ availOutputLanguages, setAvailOutputLanguages }}>
                <InputLanguageContext.Provider value={{ inputLanguage, setInputLanguage }}>
                    <OutputLanguageContext.Provider value={{ outputLanguage, setOutputLanguage }}>
                        {props.children}
                    </OutputLanguageContext.Provider>
                </InputLanguageContext.Provider>
            </AvailOutputLanguagesContext.Provider>
        </LanguageMappingContext.Provider>
    )
}
