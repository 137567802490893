import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import {createRoot} from "react-dom/client";
import './index.css';
import App from './components/App';
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import reportWebVitals from "./reportWebVitals";


const container = document.getElementById('root')
const root = createRoot(container)

root.render(
    <BrowserRouter>
        <I18nextProvider i18n={i18n}>
            <App/>
        </I18nextProvider>
    </BrowserRouter>
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();